import { Typography } from "@mui/material"
import { Box } from "@mui/system"

interface Props {
  children: string
}

const SearchOverlay = ({ children }: Props) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      height="150px"
      justifyContent="center"
      width="100%"
    >
      <Typography variant="body1">{children}</Typography>
    </Box>
  )
}

export default SearchOverlay
