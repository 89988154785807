import { useMemo } from "react"

export const useCollectionSearch: <T extends Record<string, any>>(params: {
  collection: T[]
  keys: (keyof T)[]
  term: string | null
}) => T[] = ({ collection, term, keys }) => {
  return useMemo(
    () =>
      collection.filter((item) => {
        if (!term) {
          return item
        }

        const matchingKeys = keys.filter((key) => {
          const regex = new RegExp(term, "i")
          return regex.test(item[key])
        })

        return matchingKeys.length > 0
      }),
    [collection, term, keys]
  )
}
