import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

export const KeyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m15.623 11.684 1.244-1.155a.572.572 0 0 0 .046-.049c.479-.557.478-1.476 0-2.034a.596.596 0 0 0-.055-.057L16.42 8a1.49 1.49 0 0 0-1.1-.487l-2.932-.004-.36-.458c-.143-.183-.328-.33-.653-.33H9.583a4.79 4.79 0 0 0-3.895-2.008A4.78 4.78 0 0 0 .9 9.496a4.795 4.795 0 0 0 4.788 4.79 4.778 4.778 0 0 0 4.038-2.208l.008-.014.013-.02h4.85c.208.006.678.018 1.026-.36Zm-.12-2.707.41.365a.384.384 0 0 1-.002.246l-1.201 1.116H9.412a.749.749 0 0 0-.641.363l-.179.298a3.439 3.439 0 0 1-2.902 1.582 3.455 3.455 0 0 1-3.45-3.452 3.44 3.44 0 0 1 3.446-3.442 3.452 3.452 0 0 1 2.888 1.565l.085.168c.173.324.753.324.753.324s1.136-.026 1.746 0l.354.452c.142.18.359.286.588.286l3.22.004a.15.15 0 0 1 .114.053.75.75 0 0 0 .07.072Zm-11.116 1.48a.958.958 0 1 0 0-1.915.958.958 0 0 0 0 1.916Z"
      />
    </SvgIcon>
  )
}

export default KeyIcon
