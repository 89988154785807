import React from "react"
import { Box, BoxProps } from "@mui/material"

export interface Props extends BoxProps {
  icon: React.ReactNode
  fillAndTextColor?: string
  label?: string
}

export const IconText = ({
  fillAndTextColor,
  icon,
  label,
  ...props
}: Props) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        color: fillAndTextColor,
        display: "flex",
        flexDirection: "row",
        gap: "4px",
        padding: 0,
      }}
      {...props}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          height: "18px",
          width: "18px",
          ".MuiSvgIcon-root path": {
            fill: fillAndTextColor,
          },
        }}
      >
        {icon}
      </Box>
      {label}
    </Box>
  )
}

export default IconText
