import { Typography } from "@mui/material"

import type { Children } from "types"

interface Props extends Children {}

const PageTitle = ({ children }: Props) => (
  <Typography
    variant="h1"
    component="h1"
    sx={{
      fontSize: "1.9375rem",
      fontWeight: "700",
      lineHeight: "2.25rem",
    }}
  >
    {children}
  </Typography>
)

export default PageTitle
