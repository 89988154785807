import { useEffect } from "react"
import { Button } from "@mui/material"
import ms from "ms"

import useMyOrganization from "hooks/auth/useMyOrganization"
import { useSeamQuery } from "hooks/useSeamQuery"
import useSessionStore from "hooks/useSessionStore"

import { usePanels } from "providers/PanelsProvider"

import Private from "templates/Private"

import Tile from "components/cards/Tile"
import WithSidebar from "components/layout/WithSidebar"
import {
  setShowModal,
  shouldShowModal,
} from "components/modals/OrgSetupModal/OrgSetupModal"
import BuildingsDataGrid from "components/pages/home/BuildingsDataGrid"
import TitleBar from "components/pages/TitleBar"
import Sidebar from "components/shell/Sidebar"
import HTMLTitle from "components/utility/HTMLTitle"

const Home = () => {
  const panels = usePanels()
  const organization = useMyOrganization()
  // The session store has access to the organization_id before useMyOrganization
  const organization_id = useSessionStore((state) => state.organization_id)

  const { data: buildings, isLoading: areBuildingsLoading } = useSeamQuery(
    ["buildings", "list"],
    (seam) => seam.buildings.list({})
  )

  const { data: incidents, isLoading: areIncidentsLoading } = useSeamQuery(
    ["incidents", "list"],
    (seam) =>
      seam.incidents.list({
        // Last 30 days
        between: [new Date(Date.now() - ms("30d")), new Date()],
      })
  )

  const hasIncidents = () => {
    if (typeof incidents === "undefined") return false
    return incidents.length > 0
  }

  const queries = ["is:online"]
  const { data: deviceCounts } = useSeamQuery(
    ["devices", "list_counts", {}, { queries, organization_id }],
    (seam) => seam.devices.list_counts({ queries, organization_id })
  )

  const { data: buildingCounts } = useSeamQuery(
    ["buildings", "get_count_summary"],
    (seam) => seam.buildings.get_count_summary({})
  )

  useEffect(() => {
    if (!organization_id) {
      return
    }

    if (shouldShowModal(organization_id)) {
      panels.set("orgSetupModal", true)
      setShowModal(organization_id, false)
    }
  }, [organization_id])

  const hasBuildings = (buildings || []).length > 0

  return (
    <div className="home-page">
      <HTMLTitle>Buildings</HTMLTitle>

      <WithSidebar
        sidebar={
          <Sidebar.Home
            isLoading={areBuildingsLoading || areIncidentsLoading}
            buildings={buildings ?? []}
            incidents={incidents?.slice(0, 10) ?? []}
          />
        }
      >
        <section className="content section pt-0">
          {hasBuildings && (
            <div className="grid grid-3 tl-grid-2 ml-grid-1 mb-8">
              <Tile
                value={(deviceCounts?.[0].count as number) ?? 0}
                label="Devices online"
              />
              <Tile
                value={(buildingCounts?.active_access_passes as number) ?? 0}
                label="Access passes"
              />
              <Tile
                value={(incidents?.length as number) ?? 0}
                label="Device issues"
                variant={hasIncidents() ? "error" : "default"}
                onClick={() =>
                  panels.set("issueModal", !panels.get("issueModal")?.isOpen)
                }
              />
            </div>
          )}
          <TitleBar
            title={organization?.name ?? "Home"}
            caption={
              hasBuildings ? `${buildings?.length || "0"} buildings` : ""
            }
            rightAreaContent={
              hasBuildings && (
                <Button
                  variant="outlined"
                  onClick={async () => {
                    panels.set("addBuildingModal", true)
                  }}
                  sx={{
                    ml: 2,
                  }}
                >
                  Create New Building
                </Button>
              )
            }
            noBorder={hasBuildings}
          />
          <BuildingsDataGrid />
        </section>
      </WithSidebar>
    </div>
  )
}

Home.template = Private

export default Home
