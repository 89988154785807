import { MouseEventHandler, ReactNode } from "react"
import { Box, Button, Typography } from "@mui/material"

import { colorDefaults } from "lib/mui"
import BuildingIcon from "assets/images/building-purple-large.svg"
import DeviceIcon from "assets/images/device-purple-large.svg"
import LinkedCloudIcon from "assets/images/linked-cloud-large.svg"

const iconMap = {
  building: <BuildingIcon />,
  device: <DeviceIcon />,
  linkedAccount: <LinkedCloudIcon />,
}

interface Props {
  // Icons are customly sized in Figma
  icon?: "building" | "device" | "linkedAccount"
  title: string
  content: ReactNode
  action?: {
    label: string
    onClick: MouseEventHandler<HTMLButtonElement>
  }
}

const EmptyState = ({ icon, title, content, action }: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        pt: "80px",
      }}
    >
      {icon && (
        <Box
          sx={{
            mb: "0.75rem",
          }}
        >
          {iconMap[icon]}
        </Box>
      )}

      <Typography
        sx={{
          fontSize: "1rem",
          fontWeight: 600,
          lineHeight: "134%",
          color: colorDefaults.text.textGray1,
          textAlign: "center",
          mb: "0.75rem",
        }}
      >
        {title}
      </Typography>

      <Typography
        sx={{
          maxWidth: "348px",
          fontSize: "0.875rem",
          fontWeight: 400,
          lineHeight: "134%",
          color: colorDefaults.text.textGray1,
          textAlign: "center",
        }}
      >
        {content}
      </Typography>

      {action && (
        <Box
          sx={{
            mt: "1.5rem",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={action.onClick}
          >
            {action.label}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default EmptyState
