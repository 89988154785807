import { ButtonBase } from "@mui/material"

import { usePanels } from "providers/PanelsProvider"

import Menu from "components/menus/Menu"

import { colorDefaults } from "lib/mui"
import AdminChevronIcon from "assets/images/admin-chevron.svg"

interface Props {}

const QuickActionMenu = ({}: Props) => {
  const panels = usePanels()

  return (
    <div className="relative ml-4">
      <Menu
        PaperProps={{
          className: "default-menu large-items",
        }}
        button={({ open }) => (
          <ButtonBase
            onClick={open}
            sx={{
              height: "30px",
              width: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "0.5rem",
              backgroundColor: colorDefaults.button.adminBg,
              transition: "var(--quick-2) var(--expressive)",

              "&:hover": {
                backgroundColor: colorDefaults.button.adminBgHover,
              },
            }}
          >
            <AdminChevronIcon />
          </ButtonBase>
        )}
      >
        {/*
          TODO: Uncomment action when BuildingInfoModal is merged in
        */}
        <button
          onClick={() => {
            panels.set("editBuildingModal", true)
          }}
        >
          Edit building info
        </button>
        <button onClick={() => panels.open("addDevicesModal")}>
          Add devices to this building
        </button>
        {/* <button>Assign building to member</button> */}
        <button
          className="item danger"
          onClick={() => panels.set("deleteBuildingModal", true)}
        >
          Delete this building
        </button>
        <div className="separator"></div>
        <button
          onClick={() => {
            panels.set("addBuildingModal", true)
          }}
        >
          Add new building to organization
        </button>
      </Menu>
    </div>
  )
}

export default QuickActionMenu
