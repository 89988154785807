import { useFilterOptions } from "components/pages/home/BuildingsDataGrid/useFilterOptions"
import { useCollectionSearch } from "hooks/search/useCollectionSearch"
import { useSeamQuery } from "hooks/useSeamQuery"

import { usePanels } from "providers/PanelsProvider"
import UrlQueryProvider, { useUrlQuery } from "providers/UrlQueryProvider"

import DataGridHeader from "components/data/DataGrid/DataGridHeader"
import SearchInput from "components/form/SearchInput"
import FilterMenu from "components/interactive/FilterMenu"
import SortingMenu from "components/interactive/SortingMenu"
import Results from "components/pages/home/BuildingsDataGrid/Results/Results"

const SortingKeys = {
  BUSIEST: "events-desc(24h)",
  ALPHABETICAL: "name-asc",
  DEVICE_COUNT: "devices-desc",
  ACCESS_PASS_COUNT: "access-passes-desc",
} as const

const SORTING_OPTIONS = [
  { key: SortingKeys.BUSIEST, label: "Busiest" },
  { key: SortingKeys.ALPHABETICAL, label: "A-Z" },
  { key: SortingKeys.DEVICE_COUNT, label: "Device count" },
  { key: SortingKeys.ACCESS_PASS_COUNT, label: "Most access passes" },
]

const BuildingsDataGrid = () => {
  return (
    <UrlQueryProvider defaultSortKey={SortingKeys.BUSIEST}>
      <Content />
    </UrlQueryProvider>
  )
}

const Content = () => {
  const {
    query,
    searchInputProps,
    filterKey,
    setFilterKey,
    setSortKey,
    sortKey,
    search,
  } = useUrlQuery()
  const filterOptions = useFilterOptions()
  const panels = usePanels()

  const { data: buildings, isLoading } = useSeamQuery(
    ["buildings", "list", {}, { query }],
    (seam) => seam.buildings.list({ query })
  )

  // This will get the various counts and combine with buildings
  // For large building queries, this will be an expensive server call, so an
  // alternative is to load building count summaries on demand when the
  // row is being displayed, this implementation is left here as a reference
  // only if we decide that preloading building summary counts will give us a
  // better UX
  // const building_ids: string[] =
  //   buildings?.map((building) => building.building_id) || []
  // const { data: buildingCountSummaries } = useSeamQuery(
  //   ["buildingCountSummaries", building_ids.join(",")],
  //   (seam) => seam.buildings.listCountSummaries({ building_ids: buildings })
  // )
  // const buildingsWithCounts = useMemo(
  //   buildings?.map((building, i) => ({
  //     ...building,
  //     count_summary: buildingCountSummaries[i],
  //   })),
  //   [buildings, buildingCountSummaries]
  // )

  const searchMatchingBuildings = useCollectionSearch({
    collection: buildings || [],
    keys: ["name"],
    term: search.debouncedTerm,
  })

  return (
    <>
      <DataGridHeader
        searchInput={
          <SearchInput {...searchInputProps} placeholder="Search buildings" />
        }
        sortMenu={
          <SortingMenu
            onSelect={setSortKey}
            options={SORTING_OPTIONS}
            value={sortKey}
          />
        }
        filterMenu={
          <FilterMenu
            hideIcon
            onSelect={setFilterKey}
            options={filterOptions}
            title="Only buildings with..."
            value={filterKey}
          />
        }
      />

      <Results buildings={searchMatchingBuildings} isLoading={isLoading} />
    </>
  )
}

export default BuildingsDataGrid
