import { usePanels } from "providers/PanelsProvider"

import EmptyState from "components/display/EmptyState"

const EmptyOverlay = () => {
  const panels = usePanels()

  return (
    <EmptyState
      icon="building"
      title="Add your first building"
      content="Add a building to your organization to control its devices and to grant people access to the building."
      action={{
        label: "Add a building",
        onClick: async () => {
          panels.set("addBuildingModal", true)
        },
      }}
    />
  )
}

export default EmptyOverlay
