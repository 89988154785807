import { useMemo } from "react"
import { BsExclamationCircle, BsKey } from "react-icons/bs"

import { useSeamQuery } from "hooks/useSeamQuery"

const FilterKeys = {
  ACTIVE_ACCESS_PASSES: "has:active-access-pass",
  DEVICE_ISSUES: "has:active-incidents",
} as const

export const useFilterOptions = () => {
  const { data: building_counts } = useSeamQuery(
    ["buildings", "get_count_summary"],
    (seam) => seam.buildings.get_count_summary({})
  )

  return useMemo(() => {
    // We only want to show filter options if a user has a building assigned
    if (!building_counts) {
      return []
    }

    return [
      {
        count: building_counts.active_access_passes,
        key: FilterKeys.ACTIVE_ACCESS_PASSES,
        icon: BsKey,
        label: "Active access passes",
      },
      {
        count: building_counts.incidents,
        key: FilterKeys.DEVICE_ISSUES,
        icon: BsExclamationCircle,
        label: "Device issues",
      },
    ]
  }, [building_counts])
}
