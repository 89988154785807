import { BoxProps } from "@mui/material"
import KeyIcon from "public/icons/KeyIcon"

import { IconText } from "components/data/IconText"

import { colorDefaults } from "lib/mui"

export interface Props extends BoxProps {
  passCount: number
}

export const DevicePassCount = ({ passCount, ...props }: Props) => {
  let fillAndTextColor = undefined
  const label = passCount.toString()
  const icon = <KeyIcon sx={{ width: "18px", height: "18px" }} />

  if (passCount === 0) {
    fillAndTextColor = colorDefaults.text.textGray3
  }

  return (
    <IconText
      fillAndTextColor={fillAndTextColor}
      icon={icon}
      label={label}
      {...(props as BoxProps)}
    />
  )
}

export default DevicePassCount
