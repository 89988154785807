import type { Children } from "types"

interface Props extends Children {
  sidebar?: React.ReactNode
}

const WithSidebar = ({ sidebar, children }: Props) => {
  return (
    <div className="container">
      <div className="layout-control">
        {sidebar}
        <main className="main">{children}</main>
      </div>
    </div>
  )
}

export default WithSidebar
