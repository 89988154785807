import { Box, Link as MuiLink, Typography } from "@mui/material"
import pluralize from "pluralize"

interface Props {
  handleCancelSearch?: () => void
  searching: boolean
  unit: string
}

export const NoRowsOverlay = ({
  handleCancelSearch,
  searching,
  unit,
}: Props) => {
  if (searching) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        mt={7}
      >
        <Typography variant="body1">
          No {pluralize(unit, 2)} matched your search
        </Typography>
        <MuiLink
          color="primary"
          component="button"
          onClick={handleCancelSearch}
          paragraph
          sx={{
            zIndex: 50,
          }}
          variant="body1"
        >
          Clear search terms
        </MuiLink>
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      mt={8}
    >
      <Typography variant="body1">No {pluralize(unit)} found</Typography>
    </Box>
  )
}

export default NoRowsOverlay
